import { render, staticRenderFns } from "./App.vue?vue&type=template&id=5d8a4a11&scoped=true&"
import script from "./App.vue?vue&type=script&lang=ts&"
export * from "./App.vue?vue&type=script&lang=ts&"
import style0 from "./App.vue?vue&type=style&index=0&id=5d8a4a11&prod&lang=scss&"
import style1 from "./App.vue?vue&type=style&index=1&id=5d8a4a11&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/.pnpm/vue-loader@15.10.1_css-loader@6.7.1_lodash@4.17.21_vue-template-compiler@2.7.14_webpack@5.73.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d8a4a11",
  null
  
)

export default component.exports